// $darkBlack: #282c35;
@import '../_vars.scss';
$darkBlack: #090909;
$nightWhite: hsla(0,0%,98%,1);
// $nightWhite: $yellow;
$borderWidth: 3px;

.site-content.thaipepper {
  transition: background-color 0.3s;
}
.cart.minimized {
  transition: background-color 0.3s;
}
.pages-nav {
  transition: background-color 0.3s;
  display: flex;
  flex-wrap: warp;
  align-items: center;
  ul {
    flex: 1;
  }
  .react-toggle {
    margin-right: 0.5em;
  }
}
.site-content.thaipepper.dark {
  color: $nightWhite;
  background-color: $darkBlack;
  // background-color: #222222;
  // background-color: #040404;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='18' viewBox='0 0 100 18'%3E%3Cpath fill='%23343434' fill-opacity='0.35' d='M61.82 18c3.47-1.45 6.86-3.78 11.3-7.34C78 6.76 80.34 5.1 83.87 3.42 88.56 1.16 93.75 0 100 0v6.16C98.76 6.05 97.43 6 96 6c-9.59 0-14.23 2.23-23.13 9.34-1.28 1.03-2.39 1.9-3.4 2.66h-7.65zm-23.64 0H22.52c-1-.76-2.1-1.63-3.4-2.66C11.57 9.3 7.08 6.78 0 6.16V0c6.25 0 11.44 1.16 16.14 3.42 3.53 1.7 5.87 3.35 10.73 7.24 4.45 3.56 7.84 5.9 11.31 7.34zM61.82 0h7.66a39.57 39.57 0 0 1-7.34 4.58C57.44 6.84 52.25 8 46 8S34.56 6.84 29.86 4.58A39.57 39.57 0 0 1 22.52 0h15.66C41.65 1.44 45.21 2 50 2c4.8 0 8.35-.56 11.82-2z'%3E%3C/path%3E%3C/svg%3E");
  transition: background-color 0.3s;
}
.thaipepper.dark {
  a.address-link {
    color: $nightWhite;
  }
  a.checkout-button.button {
    background: $yellow;
    text-transform: capitalize;
    color: $darkBlack;
    font-weight: 600;
  }
  img.nav-bar-logo {

    // box-sizing: border-box;
    // background: url(https://afag.imgix.net/bierhaus/logo-dark-1.png?h=300) no-repeat;
    // background-size: contain;
    // width: 260px;
    // display: block;
    // // height: 40px;
    // // margin-left: 5px;
    // padding-left: 260px !important;
    // background: blue;
    // padding-bottom: 1em;
    // margin-bottom: 5px;
  }
  .cart.minimized {
    color: $nightWhite;
    background: $darkBlack;
    // border-bottom: 2px solid $nightWhite;
  }
  a.page-link {
    font-weight: 700;
    color: $nightWhite;
    border-bottom: $borderWidth solid $darkBlack;
    &:hover, &.active {
      color: $nightWhite;
      border-bottom: $borderWidth solid $red;
    }
  }
  .pages-nav {
    color: $nightWhite;
    background: $darkBlack;
  }
  .quick-links {
    background: $darkBlack;
    color: $nightWhite;
  }
  .menu-features {
    a.navItem {
      background: black;
    }
  }
}



.thaipepper.dark {
  .landing-page {
    // background-image: url(https://images.unsplash.com/photo-1542319920155-a236a55f1a97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=50);
    // background-position: 0% 50%;

  }


  a.page-link {
    color: $nightWhite;
    border-bottom-color: $darkBlack;
    &:hover, &.active {
      border-bottom-color: $nightWhite;
    }
  }

  .landing-page {
    .text {
      color: $nightWhite;
      background: rgba(0,0,0,0.7);
    }
  }


}
$darkCheckoutPaneColor: #185500;
.dark {
  .menu-story .category-name .text {
    color: $nightWhite;
    background: none;
  }
  .category__header {
    border-bottom: 1.5px solid white;
  }
  .product-wrapper {
    color: $nightWhite;
    background-color: #222;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23bdbdbd' fill-opacity='0.18' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    .product-info {
      color: $nightWhite;
    }
    .panel, .product-modgroup {
      background: #333;
      box-shadow: 0px 2px 4px #0f0f0f;
    }
  }

  .checkout-container {
    .step-number {
      background: $nightWhite;
      color: $darkBlack;
    }
  }
  .actions {
    .button {
      color: $darkBlack;
      font-weight: 500;
    }
  }
  .cart-type-container {
    background: $darkCheckoutPaneColor;
    color: $nightWhite;
  }

  .checkout__cash-payment {
    color: $nightWhite;
    background: $darkCheckoutPaneColor;
  }
  .price-summary {
    color: $nightWhite;
  }
  .switch-button-group, .cart-type-container {
    color: $nightWhite;
    label.selected {
      background: $darkCheckoutPaneColor;
    }
    label.not-selected {
      background: #8bb487;
    }
  }
  .place-order-section {
    background: $darkCheckoutPaneColor;
    .place-order-button {
      background: #16b7ff;
    }
  }
  .footer {
    background: #111;
  }
}
